import { graphql } from "gatsby";
import { block } from "bem-cn";

import Layout from "layouts/";
import Heading from "components/Heading";
import Title from "components/Title";
import Container from "components/Container";
import ContactsCard from "components/ContactsCard";
import MainForm from "components/Forms/main";

import "./style.scss";

const cn = block("contact-page");

const ContactPage = ({ data }) => {
  const { seo, title, subtitle } = data.markdownRemark.frontmatter;

  return (
    <Layout seo={seo}>
      <Container>
        <Heading color="general" weight="bold" className={cn("title")}>
          {title}
        </Heading>
        <Title color="secondary" level={6}>
          {subtitle}
        </Title>
        <div className={cn("content")}>
          <MainForm className={cn("form", { wrap: true })} />
          <ContactsCard className={cn("card")} />
        </div>
      </Container>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query getContactsData {
    markdownRemark(frontmatter: { slug: { eq: "/contact" } }) {
      frontmatter {
        title
        seo {
          description
          image
          link
          title
        }
        subtitle
      }
    }
  }
`;
