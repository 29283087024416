import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';

import './style.scss';

const cn = block('attach-field');

const AttachField = (props) => {
	const [attachFileName, setFile] = useState('Your file up to 10 MB');
	const { ref, ...restRegister } = props.register;

	const setDefaultButtonText = (target) => {
		setFile('Your file up to 10 MB');
		target.style.color = '#d9d9d9';
	};

	useEffect(() => {
		const file = document.getElementById('contact-form-attach-field');
		const fileName = document.querySelectorAll('#attach-file-name')[0];

		const changeFileName = () => {
			if (file.files.length) {
				setFile(file.files[0].name);
				fileName.style.color = '#333333';
			} else {
				setDefaultButtonText(fileName);
			}
		};

		props.isSend && setDefaultButtonText(fileName);

		file.addEventListener('change', () => {
			changeFileName();
		});

		return () =>
			file.removeEventListener('change', (e) => {
				changeFileName();
			});
	}, [attachFileName, props.isSend]);

	return (
		<label className={`${cn('wrap')} ${props.className}`}>
			<input
				id='contact-form-attach-field'
				type='file'
				name='contact form attach file'
				ref={ref}
				{...restRegister}
				className={cn('input', { hidden: true })}
			/>
			<div className={cn('input', { field: true })}>
				<div className={cn('input', { wrap: true })}>
					<span id='attach-file-name' className={cn('file-name')}>
						{attachFileName}
					</span>
				</div>
				<div className={cn('btn')} />
			</div>
		</label>
	);
};

AttachField.defaultProps = {
	className: '',
};

AttachField.propTypes = {
	register: PropTypes.object,
	className: PropTypes.string,
};

export default AttachField;
