import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { sendText, notFillFieldText } from 'assets/constants';

import SimpleField from 'components/FormControl/inputField/_field';
import MiniForm from '../miniForm';
import AttachField from 'components/FormControl/inputField/_attach';
import { ButtonBlock } from '../forStudents';

const AdditionalField = ({ register, errors, isSend }) => (
	<>
		<SimpleField
			id='form-massage'
			tag='textarea'
			type='text'
			labelText='Message'
			rows='9'
			errorMassage={notFillFieldText}
			placeholder='Type your message here...'
			register={register('message', { required: true })}
			isError={errors?.message}
		/>
		<AttachField register={register('file')} isSend={isSend} />
	</>
);

const MainForm = ({ className }) => (
	<MiniForm
		isInline
		className={className}
		additionalField={(register, errors, isSend) => (
			<AdditionalField register={register} errors={errors} isSend={isSend} />
		)}
		buttonBlock={(isLoading) => <ButtonBlock isLoader={isLoading} />}
		withoutEula
		massage='default'
		requestUrl='contact-us'
	/>
);

MainForm.propTypes = {
	className: PropTypes.object,
};

export default MainForm;
